import React from "react";
import TopNav from "../../FeaturedComponent/Navigation/TopNav/TapNav";
import MainHeading from "../../FeaturedComponent/Headings/MainHeading/MainHeading";
import Footer from "./Footer";
import CallToAction from "../../FeaturedComponent/CallToAction/CallToAction";
import "./Footer.css";



const Terms = () => {
    return (
        <>
            <section className="terms">
                <TopNav />
                <MainHeading headingTitle="Terms And Conditions" />
                <CallToAction />
                <div className="terms_info">
                    <p>
                        SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?
                    </p>
                    <p>
                        When you purchase something from our store, as part of the buying and selling process, we collect the personal information you give us such as your name, address and email address.
                    </p>
                    <p>
                        When you browse our store, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system.
                    </p>
                    <p>
                        Email marketing (if applicable): With your permission, we may send you emails about our store, new products and other updates.
                    </p>
                    <p>
                        SECTION 2 - CONSENT
                    </p>
                    <p>
                        How do you get my consent?
                    </p>
                    <p>
                        When you provide us with personal information to complete a transaction, verify your credit card, place an order, arrange for a delivery or return a purchase, we imply that you consent to our collecting it and using it for that specific reason only.
                        If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.
                    </p>
                    <p>
                        How do I withdraw my consent?
                    </p>
                    <p>
                        If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at techsetcode@gmail.com or mailing us at: ( IT Park Rd, Subhash Nagar, Trimurtee Nagar, Nagpur, 440022)
                    </p>
                    <p>
                        SECTION 3 - DISCLOSURE
                    </p>
                    <p>
                        We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.
                    </p>
                    <p>
                        SECTION 4 - PAYMENT
                    </p>
                    <p>
                        We use Razorpay for processing payments. Razorpay do not store your card data on their servers. The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment. Your purchase transaction data is only used as long as is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.
                    </p>
                    <p>
                        Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.
                    </p>
                    <p>
                        SECTION 5 - CHANGES TO THIS PRIVACY POLICY
                    </p>
                    <p>
                        We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
                    </p>
                    <p>
                        If our store is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
                    </p>
                    <p>
                        If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact our Privacy Compliance Officer at (techsetcode@gmail.com) or by mail at ( IT Park Rd, Subhash Nagar, Trimurtee Nagar, Nagpur, 440022)
                    </p>
                </div>
            </section>
            <Footer />
        </>
    )
}



export default Terms;