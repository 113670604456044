import React from "react";
import TopNav from "../../FeaturedComponent/Navigation/TopNav/TapNav";
import MainHeading from "../../FeaturedComponent/Headings/MainHeading/MainHeading";
import "./About.css";
import QnA from "../../FeaturedComponent/QnA/QnA";
import Footer from "../Footer/Footer";
import CallToAction from "../../FeaturedComponent/CallToAction/CallToAction";



const About = () => {
    return (
        <>
            <section className="about">
                <TopNav />
                <MainHeading headingTitle="About Us" />
                <CallToAction />
                <div className="about_elements">
                    <p>
                        We are a team of passionate developers, designers, and technologists obsessed with creating exceptional software experiences. We believe that technology has the power to solve real-world problems, empower individuals, and drive positive change. Every line of code, every pixel placed, and every feature implemented is fueled by this unwavering belief.
                    </p>
                    <div className="img1">
                        <h1>Hacking Problems, Deploying Solutions</h1>
                    </div>
                    <p>
                        Our journey began with a simple idea: to bridge the gap between technical expertise and real-world needs. We saw a disconnect between the bleeding-edge technologies and the practical challenges faced by businesses and individuals. We knew there had to be a better way, a way to harness the power of technology to make a tangible impact.
                    </p>
                </div>

                <div className="question_background">
                    <h2>got some <br /> questions?</h2>
                </div>

                <QnA
                    question="Who We Are?"
                    answer="we are a community of developers who worked for other developers for developing API services, web application and mobile application. the specific services that are provided to developers will vary depending on the needs of the developer and the type of software that they are developing. However, the solutions listed above are some of the most common services that are provided to developers."
                />
                <QnA
                    question="What Makes Us Different?"
                    answer="We're not just another cog in the corporate machine. We're your code-slinging comrades, your partners in pixel-pushing crime. We speak your language, understand your struggles, and celebrate your triumphs. We're flexible, adaptable, and always up for a challenge. Think of us as your digital mercenary squad, ready to tackle any coding conundrum you throw our way."
                />
                <QnA
                    question="what is 'tech charge 1.0'?"
                    answer="It is an initiative from techsetcode developers which is provided to MSME's, Startups and Entrepreneurs who are just starting out and want to build a strong online presence. It is not possible to provide all the information here. To get more details hit the call button or just what's up we are happy to help you and clear your all the doubts."
                />
                <QnA
                    question="why choose us?"
                    answer="We believe in close collaboration with our clients. We actively listen to your needs, understand your target audience, and translate your vision into reality. We prioritize open communication and keep you informed throughout the development process, ensuring you're always on the same page. We provide regular progress reports and demos, allowing you to provide feedback and ensure the app aligns with your expectations."
                />
                <QnA
                    question="Ready To Code Something Epic?"
                    answer="Let's grab a Red Bull, crank up the coding tunes, and build something that blows minds and breaks the internet. Contact us today and let's write our next digital saga, together."
                />

            </section>
            <Footer />
        </>
    )
}



export default About;