import React from "react";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { authInitialise, database } from "../../../../FirebaseConfiguration";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";



const GAuth = () => {
    const afterLoggedIn = useNavigate();

    const onGoogleClick = async () => {

        try {
            const authenticate = authInitialise;
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(authenticate, provider);
            const user = result.user;

            console.log(user);

            const docRef = doc(database, "techsetcodeUsers", user.uid);

            const docSnap = await getDoc(docRef);

            if (!docSnap.exists()) {
                await setDoc(docRef, {
                    name: user.displayName,
                    email: user.email,
                    timeStamp: serverTimestamp(),
                });
            }

            alert("Logged In Successfully");
            afterLoggedIn("/");

        } catch (error) {
            alert(error.message);
            console.log(error.message);
        }

    }



    // const onGoogleClickOnAndi = async () => {

    //     try {
    //         const authenticate = authInitialise;
    //         const provider = new GoogleAuthProvider();
    //         const res = await getRedirectResult(authenticate, provider);
    //         const user = res.user;

    //         console.log(user);

    //         const docRef = doc(database, "techsetcodeUsers", user.uid);

    //         const docSnap = await getDoc(docRef);

    //         if (!docSnap.exists()) {
    //             await setDoc(docRef, {
    //                 name: user.displayName,
    //                 email: user.email,
    //                 timeStamp: serverTimestamp(),
    //             });
    //         }

    //         alert("Login Successfully");
    //         afterLoggedIn("/");
    //     } catch (error) {
    //         alert(error.message);
    //         console.log(error.message);
    //     }
    // }

    return (
        <>
            <div className="form_buttons for_desktop">
                <button type="submit" className="x_y_axis_center" onClick={onGoogleClick}>
                    <FcGoogle className="oauth_icon" />
                    &nbsp; Continue With Google
                </button>
            </div>

            {/* <div className="form_buttons for_andi">
                <button type="button" className="x_y_axis_center" onClick={onGoogleClickOnAndi}>
                    <FcGoogle className="oauth_icon" />
                    &nbsp; Continue With Google
                </button>
            </div> */}
        </>
    )
}



export default GAuth;

// import React from "react";
// import { FcGoogle } from "react-icons/fc";
// import { useNavigate } from "react-router-dom";
// import { authInitialise, firebase } from "../../../../FirebaseConfiguration"; // Replace with your import method
// import { GoogleAuthProvider, getRedirectResult, signInWithPopup, signInWithRedirect } from "firebase/auth";
// import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";

// const GAuth = () => {
//     const navigate = useNavigate(); // Use navigate instead of afterLoggedIn

//     const onGoogleClick = async () => {
//         try {
//             const auth = authInitialise; // Use actual Firebase Authentication instance
//             const provider = new GoogleAuthProvider();
//             await signInWithPopup(auth, provider); // Use signInWithPopup for desktop

//             // Rest of the code remains the same (user data, document creation, etc.)

//             alert("Registered Successfully");
//             navigate("/"); // Use navigate instead of afterLoggedIn
//         } catch (error) {
//             alert(error.message);
//             console.log(error.message);
//         }
//     };

//     const onGoogleClickOnAndi = async () => {
//         try {
//             const auth = authInitialise; // Use actual Firebase Authentication instance
//             const provider = new GoogleAuthProvider();
//             const result = await signInWithRedirect(auth, provider); // Use signInWithRedirect for mobile

//             if (result.user) { // Check for successful redirect
//                 // User successfully logged in, handle further actions

//                 // Example:
//                 await getDoc(doc(authInitialise, "techsetcodeUsers", result.user.uid)); // Access user data
//                 alert("Login Successfully");
//                 navigate("/"); // Use navigate instead of afterLoggedIn
//             } else {
//                 // Handle redirect error
//                 console.error("Redirect failed for Google sign-in");
//             }
//         } catch (error) {
//             alert(error.message);
//             console.log(error.message);
//         }
//     };

//     return (
//         <>
//             <div className="form_buttons for_desktop">
//                 <button type="submit" className="x_y_axis_center" onClick={onGoogleClick}>
//                     <FcGoogle className="oauth_icon" />
//                     &nbsp; Continue With Google
//                 </button>
//             </div>

//             <div className="form_buttons for_andi">
//                 <button type="button" className="x_y_axis_center" onClick={onGoogleClickOnAndi}>
//                     <FcGoogle className="oauth_icon" />
//                     &nbsp; Continue With Google
//                 </button>
//             </div>
//         </>
//     );
// };

// export default GAuth;
