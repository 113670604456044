// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA2qfceXSJTDHuPOUQqsasmrICXPLNGXxg",
    authDomain: "techsetcode-3e25b.firebaseapp.com",
    projectId: "techsetcode-3e25b",
    storageBucket: "techsetcode-3e25b.appspot.com",
    messagingSenderId: "427573905821",
    appId: "1:427573905821:web:53996b41d6aface5e1c774",
    measurementId: "G-CFK93TBTEJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const authInitialise = getAuth(app);
const database = getFirestore(app);
const storage = getStorage(app);



export { analytics, authInitialise, database, storage };
