import React from "react";
import "./Footer.css";
import { IoMdContact } from "react-icons/io";
import { IoShareSocial } from "react-icons/io5";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { FcRules } from "react-icons/fc";
import { Link } from "react-router-dom";
import { MdCopyright } from "react-icons/md";
import { CiLinkedin } from "react-icons/ci";
import { RiInstagramLine } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa6";
import { BiLogoGmail } from "react-icons/bi";
import CallToAction from "../../FeaturedComponent/CallToAction/CallToAction";



const Footer = () => {
    return (
        <>
            <CallToAction />
            <section className="footer">
                <div className="f_contents">
                    <div className="f_elements">
                        <a href="https://www.linkedin.com/in/techsetcode-solutions-704719293/" className="y_axis_center"><CiLinkedin /> &nbsp; linkedIn</a>
                        <br />
                        <a href="https://www.instagram.com/techsetcode?igshid=ZTM4ZDRiNzUwMw%3D%3D" className="y_axis_center"><RiInstagramLine /> &nbsp; Instagram</a>
                        <br />
                        <a href="https://wa.me/+919766940948/?text=How We Can Help You...?" className="y_axis_center"><FaWhatsapp /> &nbsp; Whats App</a>
                        <br />
                        <a href="mailto:techsetcode@gmail.com" className="y_axis_center"><BiLogoGmail /> &nbsp; GMail</a>
                    </div>
                    <div className="f_elements">
                        <Link to="/ContactUs" className="y_axis_center"><IoMdContact /> &nbsp; contact us</Link>
                        <br />
                        {/* <Link to="/" className="y_axis_center"><IoShareSocial /> &nbsp; social media</Link> */}
                        <Link to="/" className="y_axis_center"><MdOutlinePrivacyTip /> &nbsp; privacy and policy</Link>
                        <br />
                        <Link to="/Terms" className="y_axis_center"><FcRules /> &nbsp; terms and conditions</Link>
                        <br />
                        <Link to="/" className="y_axis_center"><MdCopyright /> &nbsp; Copyright 2023-2024</Link>
                    </div>
                </div>
            </section>
        </>
    )
}



export default Footer;
