import React, { useState } from "react";
import "./Register.css";
import TopNav from "../../Navigation/TopNav/TapNav";
import MainHeading from "../../Headings/MainHeading/MainHeading";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { authInitialise, database } from "../../../../FirebaseConfiguration";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import Footer from "../../../Pages/Footer/Footer";
import contact from "../../../../Assets/Image/contact.svg";
import CallToAction from "../../CallToAction/CallToAction";



const proIdentity = [
    "Entrepreneur",
    "Full Stack Engineer",
    "Frontend Developer",
    "Backend Developer",
    "IoT Developer",
    "Data Analyst",
    "Fresher"
]
const Register = () => {

    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        professionalIdentity: "",
        mobileNumber: "",
        email: "",
        password: "",
        confirmPass: "",
    });

    const { firstname, lastname, professionalIdentity, mobileNumber, email, password, confirmPass } = formData;

    const [showPass, setShowPass] = useState(false);

    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const handleFormData = (event) => {
        console.log(event.target.value);

        setFormData((previousState) => ({
            ...previousState,

            [event.target.id]: event.target.value
        }))
    }

    const userNavigateAfterSignUp = useNavigate();

    const submitFormData = async (event) => {

        event.preventDefault();

        try {
            if (password !== confirmPass) {
                alert("Password You Entered Is Not Matched");
            }

            if (firstname && lastname && professionalIdentity && mobileNumber && email && password && confirmPass) {
                const userCredential = await createUserWithEmailAndPassword(
                    authInitialise,
                    email,
                    password,
                )

                await updateProfile(authInitialise.currentUser, {
                    displayName: `${firstname} ${lastname}`
                });

                const user = userCredential.user;

                const formDataCopy = { ...formData };

                delete formDataCopy.password;

                delete formDataCopy.confirmPass;

                formDataCopy.timeStamp = serverTimestamp();

                await setDoc(doc(database, "techsetcodeUsers", user.uid), {
                    ...formDataCopy,
                });

                alert("Registration Successful");

                userNavigateAfterSignUp("/");
            } else {
                return alert("All Fields Are Mandatory");
            }
        } catch (error) {

            console.log(error.message);
            alert(error.message);

        }
    }

    return (
        <>
            <section className="user_auth">
                <TopNav />
                <MainHeading headingTitle="Register" />
                <CallToAction />

                <div className='register'>
                    <form onSubmit={submitFormData}>
                        <input
                            type="text"
                            id="firstname"
                            value={firstname}

                            className="input_field"
                            placeholder="Firstname"
                            onChange={handleFormData}
                        />

                        <input
                            type="text"
                            id="lastname"
                            value={lastname}
                            className="input_field"
                            placeholder="Lastname"
                            onChange={handleFormData}
                        />

                        <select
                            id="professionalIdentity"
                            className="input_field quali"
                            placeholder="Professional Identity"
                            onChange={handleFormData}
                        >
                            <option>Professional Identity</option>

                            {
                                proIdentity.map((option, index) => (
                                    <option value={option || ""} key={index}>
                                        {option}
                                    </option>
                                ))
                            }
                        </select>

                        <input
                            type="number"
                            id="mobileNumber"
                            value={mobileNumber}
                            className="input_field"
                            placeholder="Mobile Number"
                            onChange={handleFormData}
                        />

                        <input
                            type="email"
                            id="email"
                            value={email}
                            className="input_field"
                            placeholder="Email"
                            onChange={handleFormData}
                        />

                        <input
                            type={showPass ? "text" : "password"}
                            id="password"
                            value={password}
                            className="input_field"
                            placeholder="Password"
                            onChange={handleFormData}
                        />

                        {
                            showPass ?
                                (
                                    <AiFillEye
                                        className="eye_invisible"
                                        onClick={() => setShowPass((prevState) => !prevState)}
                                    />
                                )
                                :
                                (
                                    <AiFillEyeInvisible
                                        className="eye_invisible"
                                        onClick={() => setShowPass((prevState) => !prevState)}
                                    />
                                )
                        }

                        <input
                            type={showConfirmPass ? "text" : "password"}
                            id="confirmPass"
                            value={confirmPass}
                            className="input_field"
                            placeholder="Confirm Password"
                            onChange={handleFormData}
                        />

                        {
                            showConfirmPass ?
                                (
                                    <AiFillEye
                                        className="confirm_pass_eye_invisible"
                                        onClick={() => setShowConfirmPass((prevState) => !prevState)}
                                    />
                                )
                                :
                                (
                                    <AiFillEyeInvisible
                                        className="confirm_pass_eye_invisible"
                                        onClick={() => setShowConfirmPass((prevState) => !prevState)}
                                    />
                                )
                        }

                        <div className="form_buttons">
                            <button type="submit">Register</button>
                        </div>
                    </form>

                    <div className="img">
                        <h2>Work Insane Or Remain The Same</h2>
                        <img src={contact} alt="contact" />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}



export default Register;