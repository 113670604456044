import React, { useState } from "react";
import "./TopNav.css";
import logo1 from "../../../../Assets/Image/logo1.png";
import { Link, NavLink } from "react-router-dom";
import { BsJournalCode } from "react-icons/bs";
import { LiaBloggerB } from "react-icons/lia";
// import { GrProjects } from "react-icons/gr";
// import { PiQrCodeBold } from "react-icons/pi";
// import { BiSolidUserCircle } from "react-icons/bi";
import { LuContact2 } from "react-icons/lu";
import { FcAbout } from "react-icons/fc";
import { BiUserCircle } from "react-icons/bi";




const TopNav = () => {

    const [showMenu, setShowMenu] = useState(false);

    const toggleMobileMenu = () => {
        setShowMenu(!showMenu);
        document.querySelector("body").classList.toggle("body-overflow-visible");
    };

    return (
        <>
            <section className="top_nav">

                <div className="nav_elements y_axis_center">
                    <div className="logo y_axis_center">
                        <img src={logo1} alt="" />
                        <NavLink to="/" className="">
                            <h1><span className="techset">TechSet</span><span className="code">Code</span></h1>
                        </NavLink>
                    </div>

                    <div className="nav_icon y_axis_center">
                        <div className="menu_icon x_y_axis_center">
                            <div className="only-mobile mobile-menu-button-wrapper">
                                <button
                                    className={`hamburger hamburger--3dx x_y_axis_center ${showMenu ? "is-active" : ""
                                        }`}
                                    type="button"
                                    onClick={toggleMobileMenu}
                                >
                                    <span className="hamburger-box">
                                        <span className="hamburger-inner"></span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

            </section>

            <div
                className={`navigation_section navigation_style ${showMenu ? "overlay" : ""
                    }`}
                id="Navigation"
            >
                <div className="nav_links">
                    <NavLink to="/About" className="nav_sub_section x_y_axis_center">
                        <h1 className="section_name">About</h1>
                        <div className="section_icon x_y_axis_center">
                            <FcAbout />
                        </div>
                    </NavLink>
                    <NavLink to="/ContactUs" className="nav_sub_section x_y_axis_center">
                        <h1 className="section_name">Contact</h1>
                        <div className="section_icon x_y_axis_center">
                            <LuContact2 />
                        </div>
                    </NavLink>
                    <Link to="/Login" className="nav_sub_section x_y_axis_center">
                        <h1 className="section_name">Authenticate</h1>
                        <div className="section_icon x_y_axis_center">
                            <BiUserCircle />
                        </div>
                    </Link>
                    <div className="nav_sub_section x_y_axis_center">
                        <h1 className="section_name">Code Lab</h1>
                        <div className="section_icon x_y_axis_center">
                            <BsJournalCode />
                        </div>
                    </div>
                    <div className="nav_sub_section x_y_axis_center">
                        <h1 className="section_name">Case Studies</h1>
                        <div className="section_icon x_y_axis_center">
                            <LiaBloggerB />
                        </div>
                    </div>
                    {/* <div className="nav_sub_section y_axis_center">
                        <div className="section_icon x_y_axis_center">
                            <PiQrCodeBold />
                        </div>
                        <h1 className="section_name">Projects</h1>
                    </div> */}
                </div>
            </div>
        </>
    )
};



export default TopNav;
