import React from "react";
import "./LandingPage.css";
import TopNav from "../../FeaturedComponent/Navigation/TopNav/TapNav";
import LandingPageContent from "./LandingPageContent";
import SubHead from "../../FeaturedComponent/Headings/SubHeadings/SubHeading";
import CollaborateWith from "../../FeaturedComponent/Carousel/CollaborateWith";
import TechCharge from "../../FeaturedComponent/TechCharge/TechCharge";
import CallToAction from "../../FeaturedComponent/CallToAction/CallToAction";
import DevCorner from "../../FeaturedComponent/DevCorner/DevCorner";
import TechTuts from "../../FeaturedComponent/TechTuts/TechTuts";
import QnA from "../../FeaturedComponent/QnA/QnA";
import Footer from "../Footer/Footer";



const LandingPage = () => {
    return (
        <>
            <p>
                Web App Updated Soon...
            </p>
            {/* <section className="landing_page">
                <TopNav />
                <LandingPageContent />
            </section>
            <SubHead headTitle="Collaborate With" />
            <CollaborateWith />
            <DevCorner />
            <TechTuts />
            <TechCharge />
            <CallToAction />
            <div className="question_background">
                <h2>got some <br /> questions?</h2>
            </div>
            <QnA
                question="Who We Are?"
                answer="we are a community of developers who worked for other developers for developing API services, web application and mobile application. the specific services that are provided to developers will vary depending on the needs of the developer and the type of software that they are developing. However, the solutions listed above are some of the most common services that are provided to developers."
            />
            <QnA
                question="what is 'tech charge 1.0'?"
                answer="It is an initiative from techsetcode developers which is provided to MSME's, Startups and Entrepreneurs who are just starting out and want to build a strong online presence. It is not possible to provide all the information here. To get more details hit the call button or just what's up we are happy to help you and clear your all the doubts."
            />
            <QnA
                question="why choose us?"
                answer="We believe in close collaboration with our clients. We actively listen to your needs, understand your target audience, and translate your vision into reality. We prioritize open communication and keep you informed throughout the development process, ensuring you're always on the same page. We provide regular progress reports and demos, allowing you to provide feedback and ensure the app aligns with your expectations."
            />
            <QnA
                question="Ready To Code Something Epic?"
                answer="Let's grab a Red Bull, crank up the coding tunes, and build something that blows minds and breaks the internet. Contact us today and let's write our next digital saga, together."
            />

            <Footer /> */}
        </>
    )
};



export default LandingPage;
