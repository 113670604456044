import React from "react";
import "./App.css";
import ReactRouter from "./ReactRouterDOM/ReactRouter";



const App = () => {
    return (
        <>
            <ReactRouter />
        </>
    )
};



export default App;