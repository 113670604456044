import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../Components/Pages/LandingPage/LandingPage";
import Register from "../Components/FeaturedComponent/Authentication/Register/Register";
import Login from "../Components/FeaturedComponent/Authentication/Login/Login";
import Terms from "../Components/Pages/Footer/Terms";
import ContactUs from "../Components/FeaturedComponent/Authentication/Contact/ContactUs";
import About from "../Components/Pages/About/About";



const ReactRoutes = () => {
    return (
        <>
            <Routes>
                <Route exact path="/" element={<LandingPage />}></Route>
                <Route path="/Login" element={<Login />}></Route>
                <Route path="/Register" element={<Register />}></Route>
                <Route path="/ContactUs" element={<ContactUs />}></Route>
                <Route path="/About" element={<About />}></Route>
                <Route path="/Terms" element={<Terms />}></Route>
            </Routes>
        </>
    )
};



export default ReactRoutes;
