import React, { useState } from "react";
import "./CallToAction.css";
import { BiSolidPhoneCall } from "react-icons/bi";
import { IoLogoWhatsapp } from "react-icons/io5";
import { SiMinutemailer } from "react-icons/si";



const CallToAction = () => {

    const [showLinks, setShowLinks] = useState(false);

    const toggleLinks = () => {
        setShowLinks(!showLinks);
        document.querySelector("body").classList.toggle("body-overflow-visible");
    };

    return (
        <>
            <div
                className={`call_to_action ${showLinks ? "is_active" : ""}`}
                type="button"
                onClick={toggleLinks}
            >
                <SiMinutemailer />
            </div>

            <div className={`call links ${showLinks ? "overlay" : ""}`}>
                <a href="https://wa.me/+919766940948/?text=How We Can Help You...?" className="x_y_axis_center">
                    <IoLogoWhatsapp />
                </a>
            </div>
            <div className={`whats_app links ${showLinks ? "overlay" : ""}`}>
                <a href="tel:+917620174776" className="x_y_axis_center">
                    <BiSolidPhoneCall />
                </a>
            </div>
        </>
    )
};



export default CallToAction;
