import React, { useState } from "react";
import TopNav from "../../Navigation/TopNav/TapNav";
import "../Register/Register.css";
import MainHeading from "../../Headings/MainHeading/MainHeading";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import Spinner from "../../../../Assets/Spinner/Spinner";
import { database } from "../../../../FirebaseConfiguration";
import Footer from "../../../Pages/Footer/Footer";
import contact from "../../../../Assets/Image/contact.svg";
import CallToAction from "../../CallToAction/CallToAction";



const proIdentity = [
    "Entrepreneur",
    "Full Stack Engineer",
    "Frontend Developer",
    "Backend Developer",
    "IoT Developer",
    "Data Analyst",
    "Fresher"
]

const ContactUs = () => {



    const [formData, setFormData] = useState({
        fullName: "",
        professionalIdentity: "",
        mobileNumber: "",
        enquiryDetails: "",
    });



    const [loading, setLoading] = useState(false);



    const { fullName, professionalIdentity, mobileNumber, enquiryDetails } = formData;



    const formDataCopy = {
        ...formData,
        timestamp: serverTimestamp(),
    }



    const handleFormData = (event) => {
        console.log(event.target.value);

        setFormData((previousState) => ({
            ...previousState,

            [event.target.id]: event.target.value
        }))
    }



    const submitFormData = async (event) => {
        event.preventDefault();

        setLoading(true);

        try {
            if (fullName && professionalIdentity && mobileNumber && enquiryDetails) {
                await addDoc(collection(database, "techsetcodeEnquiries"), {
                    ...formDataCopy,
                });

                setLoading(false);
                alert("Enquiry Successfully Submitted. We Reached Out To You Soon.");
            } else {
                alert("All Fields Are Mandatory");
                setLoading(false);
            }
        } catch (error) {
            console.log(error.message);
            alert(error.message);
        }
    }

    if (loading) {
        return <Spinner />
    }

    return (
        <>
            <section className="user_auth">
                <TopNav />
                <MainHeading headingTitle="Contact Us" />
                <CallToAction />

                <div className="register">
                    <form action="" onSubmit={submitFormData}>
                        <input
                            type="text"
                            id="fullName"
                            value={fullName}
                            className="input_field"
                            placeholder="Your Name"
                            onChange={handleFormData}
                        />

                        <select
                            id="professionalIdentity"
                            className="input_field quali"
                            placeholder="Professional Identity"
                            onChange={handleFormData}
                        >
                            <option>Professional Identity</option>

                            {
                                proIdentity.map((option, index) => (
                                    <option value={option || ""} key={index}>
                                        {option}
                                    </option>
                                ))
                            }
                        </select>

                        <input
                            type="number"
                            id="mobileNumber"
                            value={mobileNumber}
                            className="input_field"
                            placeholder="Mobile Number"
                            onChange={handleFormData}
                        />

                        <textarea
                            cols="30"
                            rows="10"
                            id="enquiryDetails"
                            value={enquiryDetails}
                            className="input_field"
                            placeholder="Write us and clear your all the doubts here..."
                            onChange={handleFormData}
                        ></textarea>

                        <div className="form_buttons">
                            <button type="submit">Enquiry Now</button>
                        </div>
                    </form>

                    <div className="img">
                        <h2>Do One Thing Everyday That Scares You</h2>
                        <img src={contact} alt="contact" />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}



export default ContactUs;
